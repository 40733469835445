import React from "react";

export default class SettingMenu extends React.Component {
  render() {
    // const { onToggle } = this.props;

    return (
      <a
        className="nav-link right-bar-toggle waves-light waves-effect"
        // onClick={onToggle}
        // href="javascript: void(0)"
      >
        <i className="mdi mdi-dots-horizontal noti-icon" />
      </a>
    );
  }
}

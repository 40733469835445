import React from "react";
import { connect } from "react-redux";
// import { Layout } from "antd";

import settingsAction from "redux/settings/actions";

import Logo from "./Logo";
import Fullscreen from "./Fullscreen";
import SettingMenu from "./SettingMenu";
import NotificationMenu from "./NotificationMenu";
import ProfileMenu from "./ProfileMenu";
import SidebarCollapseTrigger from "./SidebarCollapseTrigger";
// import styles from "./style.module.scss";

const mapStateToProps = ({ settings }) => ({
  isMenuCollapsed: settings.isMenuCollapsed,
  isSettingsOpen: settings.isSettingsOpen
});
const mapDispatchToProps = {
  changeSetting: settingsAction.changeSetting
};

@connect(
  mapStateToProps,
  mapDispatchToProps
)
class TopBar extends React.Component {
  onSidebarCollapseTrigger = () => {
    const { changeSetting, isMenuCollapsed } = this.props;
    changeSetting({
      setting: "isMenuCollapsed",
      value: !isMenuCollapsed
    });
  };

  onSettingTrigger = () => {
    const { changeSetting, isSettingsOpen } = this.props;

    changeSetting({
      setting: "isSettingsOpen",
      value: !isSettingsOpen
    });
  };

  render() {
    return (
      <div className="topbar">
        <Logo />
        {/* Button mobile view to collapse sidebar menu  */}
        <nav className="navbar-custom">
          <ul className="list-inline float-right mb-0">
            <li className="list-inline-item notification-list hide-phone">
              <Fullscreen />
            </li>
            <li className="list-inline-item notification-list">
              <SettingMenu onToggle={this.onSettingTrigger} />
            </li>
            <li className="list-inline-item notification-list dropdown">
              <NotificationMenu />
            </li>
            <li className="list-inline-item notification-list dropdown">
              <ProfileMenu />
            </li>
          </ul>
          <ul className="list-inline menu-left mb-0">
            <li className="float-left" style={{ lineHeight: 1.5 }}>
              <SidebarCollapseTrigger
                onTrigger={this.onSidebarCollapseTrigger}
              />
            </li>
            <li className="hide-phone app-search">
              <form>
                <input
                  type="text"
                  placeholder="Search..."
                  className="form-control"
                />
                <a href="">
                  <i className="fa fa-search" />
                </a>
              </form>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

export default TopBar;

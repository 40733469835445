import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

@connect(
  ({ auth }) => ({ auth }),
  null
)
class Logo extends React.Component {
  render() {
    const { auth } = this.props;

    return (
      <div className="topbar-left">
        <div className="text-center">
          {auth.is_admin.includes(1) || auth.is_admin.includes(3) ? (
            <Link to="/dashboard" className="logo">
              <i className="mdi mdi-radar" /> <span>Duet</span>
            </Link>
          ) : (
            <Link to="/rider-services/bookings" className="logo">
              <i className="mdi mdi-radar" /> <span>Duet</span>
            </Link>
          )}
        </div>
      </div>
    );
  }
}

export default Logo;

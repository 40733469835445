import "rc-drawer/assets/index.css";
import React from "react";
// import DrawerMenu from "rc-drawer";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import MenuLeft from "./MenuLeftBlue";

const mapStateToProps = ({ settings }) => ({
  isMobileMenuOpen: settings.isMobileMenuOpen,
  isMobileView: settings.isMobileView
});

@withRouter
@connect(mapStateToProps)
class AppMenu extends React.Component {
  toggleOpen = () => {
    const { dispatch, isMobileMenuOpen } = this.props;
    document
      .querySelector("#root")
      .setAttribute(
        "style",
        !isMobileMenuOpen ? "overflow: hidden; width: 100%; height: 100%;" : ""
      );
    dispatch({
      type: "settings/CHANGE_SETTING",
      payload: {
        setting: "isMobileMenuOpen",
        value: !isMobileMenuOpen
      }
    });
  };

  render() {
    // const { isMobileMenuOpen, isMobileView } = this.props;
    const BootstrappedMenu = () => {
      // if (isMobileView) {
      //   return (
      //     <DrawerMenu
      //       getContainer={null}
      //       level="all"
      //       open={isMobileMenuOpen}
      //       onMaskClick={this.toggleOpen}
      //       onHandleClick={this.toggleOpen}
      //     >
      //       <MenuLeft />
      //     </DrawerMenu>
      //   );
      // }

      return <MenuLeft />;
    };

    return BootstrappedMenu();
  }
}

export default AppMenu;

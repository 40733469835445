/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import aws from "aws-sdk";
import parse from "./_parse";
import "../config";

const env = window._env_.DEPLOYMENT_PROFILE;

aws.config.update({
  region:
    env === "dev" || env === "preDev"
      ? process.env.REACT_APP_DevRegion
      : process.env.REACT_APP_ProdRegion,
  accessKeyId:
    env === "dev" || env === "preDev"
      ? process.env.REACT_APP_DevAccessKeyId
      : process.env.REACT_APP_ProdAccessKeyId,
  secretAccessKey:
    env === "dev" || env === "preDev"
      ? process.env.REACT_APP_DevSecretAccessKey
      : process.env.REACT_APP_ProdSecretAccessKey
});
const s3 = new aws.S3();

export async function fetchFleetShuttleList() {
  try {
    const lists = await parse.Cloud.run("v1_webapp_get_fleet_shuttle_list");
    return lists;
  } catch (error) {
    console.log("fetchFleetShuttleList() = ", error);
    return [];
  }
}

export async function fetchFleetAvailableShuttleList() {
  try {
    const lists = await parse.Cloud.run(
      "v1_webapp_get_fleet_available_shuttles"
    );
    return lists;
  } catch (error) {
    console.log("fetchFleetAvailableShuttleList() = ", error);
    return [];
  }
}

export async function fetchFleetShuttleInfoByID(param) {
  try {
    const data = await parse.Cloud.run(
      "v1_webapp_get_shuttle_info_by_id",
      param
    );
    // console.log(data);
    return data;
  } catch (error) {
    console.log("services/vehicle.js : fetchFleetShuttleInfoByID = ", error);
    return {};
  }
}

export async function fetchLabel() {
  try {
    const data = await parse.Cloud.run("v1_webapp_get_user_fleet_name");
    return { status: 1, data };
  } catch (error) {
    console.log("fetchLabel() = ", JSON.stringify(error));
    return { status: 0, data: error };
  }
}

export async function fetchPrograms() {
  const data = await parse.Cloud.run("v1_webapp_get_user_program_name");
  // console.log(data);
  return data.data.programs;
}

export async function uploadImageToS3(params) {
  const s3Params = {
    // Bucket: env === "dev" ? devBucket : prodBucket,
    Bucket:
      env === "dev" || env === "preDev"
        ? process.env.REACT_APP_DevBucket
        : process.env.REACT_APP_ProdBucket,
    Body: params,
    Key: params.name,
    Expires: 500,
    ContentType: params.type,
    ACL: "public-read"
  };

  // console.log(s3Params);

  return new Promise(resolve => {
    const options = { partSize: 10 * 1024 * 1024, queueSize: 1 };
    // eslint-disable-next-line func-names
    s3.upload(s3Params, options, function(err, data) {
      if (err) {
        // console.log("file upload fail");
        // console.log(err, err.stack); // an error occurred
        // console.log(JSON.stringify(err));
        resolve({ status: false, desc: err });
      } else {
        // console.log("file upload success", JSON.stringify(data));
        resolve({ status: true, desc: data });
      }
    });
  });
}

export async function createNewShuttle(params) {
  // console.log("createNewShuttle = ", params);
  const {
    license_plate,
    vin,
    make,
    model,
    year,
    color,
    vehicle_capacity,
    wheelchair,
    bike_rack,
    fleet_shuttle_id,
    serviceable_programs,
    status,
    photo,
    selectedGurneyLists
  } = params;

  let statusCode = 0;
  if (status.trim() === "Active") statusCode = 0;
  else if (status.trim() === "Suspended") statusCode = 1;
  else if (status.trim() === "Dismissed") statusCode = 2;

  const realParam = {
    license_plate,
    vin: vin.trim() === "" ? null : vin.trim(),
    make,
    model,
    year,
    color,
    vehicle_capacity: parseInt(vehicle_capacity, 10),
    wheelchair,
    bike_rack,
    fleet_shuttle_id,
    serviceable_programs,
    status: parseInt(statusCode, 10),
    photo,
    gurney: selectedGurneyLists
  };
  // console.log(realParam);
  try {
    const data = await parse.Cloud.run(
      "v1_webapp_create_new_shuttle",
      realParam
    );
    // console.log(data);
    if (data.status === "success") {
      return true;
    }

    return false;
  } catch (error) {
    return false;
  }
}

export async function updateExistShuttle(params) {
  // console.log("udpateExistShuttle = ", params);
  const {
    license_plate,
    vin,
    make,
    model,
    year,
    color,
    vehicle_capacity,
    wheelchair,
    bike_rack,
    fleet_shuttle_id,
    serviceable_programs,
    status,
    photo,
    photo_replaced,
    license_plate_replaced,
    shuttle_id,
    selectedGurneyLists
  } = params;

  let statusCode = 0;
  if (status.trim() === "Active") statusCode = 0;
  else if (status.trim() === "Suspended") statusCode = 1;
  else if (status.trim() === "Dismissed") statusCode = 2;

  const realParam = {
    license_plate,
    vin: vin.trim() === "" ? null : vin.trim(),
    make,
    model,
    year,
    color,
    vehicle_capacity: parseInt(vehicle_capacity, 10),
    wheelchair,
    bike_rack,
    fleet_shuttle_id,
    serviceable_programs,
    status: parseInt(statusCode, 10),
    photo,
    license_plate_replaced,
    photo_replaced,
    shuttle_id,
    gurney: selectedGurneyLists
  };
  // console.log(realParam);
  try {
    const data = await parse.Cloud.run("v1_webapp_edit_shuttle", realParam);
    // console.log(data);
    if (data.status === "success") {
      return true;
    }

    return false;
  } catch (error) {
    return false;
  }
}

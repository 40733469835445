import { notification } from "antd";
import parse from "./_parse";

export async function login(email, password) {
  return parse.User.logIn(email, password)
    .then(res => {
      // console.log("sssssss");
      // console.log(JSON.stringify(res));

      if (Array.isArray(res.get("is_admin"))) return true;

      notification.warning({
        message: "Logged In",
        description: "You need to be authorized to access this application."
      });

      return false;
    })
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message
      });
    });
}

export async function currentAccount() {
  return new Promise(resolve => {
    const currentUser = parse.User.current();

    resolve(currentUser);
  });
}

export async function logout() {
  return parse.User.logOut();
}

export async function checkEmail(param) {
  try {
    const data = await parse.Cloud.run("v1_webapp_check_email", param);
    return data;
  } catch (error) {
    console.log("auth::checkEmail() = ", JSON.stringify(error));
    return error;
  }
}

export async function sendRequestResetPassword(param) {
  parse.User.requestPasswordReset(param.email)
    .then(() => {
      // Password reset request was sent successfully
    })
    .catch(error => {
      // Show the error message somewhere
      console.log(
        `auth::sendRequestResetPassword, Error: ${error.code} ${error.message}`
      );
    });
}

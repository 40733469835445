/* eslint-disable camelcase */
import parse from "../_parse";

export async function fetchProgramsList() {
  try {
    const lists = await parse.Cloud.run("v1_webapp_get_fleet_programs ");
    return lists;
  } catch (error) {
    console.log("fetchDriversList() = ", JSON.stringify(error));
    return error;
  }
}

export async function fetchBookingInfoByID(param) {
  try {
    const response = await parse.Cloud.run(
      "v1_webapp_get_booking_info_by_id",
      param
    );
    // console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log(JSON.stringify(error));
    return error;
  }
}

export async function fetchProgramPricingInfo(params) {
  try {
    const response = await parse.Cloud.run(
      "v1_webapp_get_program_pricing_info",
      params
    );
    // console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log(JSON.stringify(error));
    return error;
  }
}

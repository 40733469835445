import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import * as serviceWorker from "./serviceWorker";
import configureStore, { history, bootstrapSaga } from "./configureStore";
import Router from "./route";

import "./global.scss";

const target = document.querySelector("#root");
const store = configureStore();
bootstrapSaga();

ReactDOM.render(
  <Provider store={store}>
    <Router history={history} />
  </Provider>,
  target
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

// Being used in MainLayout redux
export { store, history };

/* eslint-disable no-underscore-dangle, no-nested-ternary */
import Parse from "parse";

const env = window._env_.DEPLOYMENT_PROFILE;
const config = {
  preDev: {
    serverUrl: "https://duet-predev.herokuapp.com/1",
    appId: "cuM6t1j3fKQAKMKnRwdPp8m0KhnKNlF1K5EkJ9cD",
    apiKey: "quxSuw1kbVNPlp1RdfTwpNvKMQNUOIk4SnTKIZw8"
  },
  dev: {
    serverUrl: "https://duet-develop.herokuapp.com/1",
    appId: "cuM6t1j3fKQAKMKnRwdPp8m0KhnKNlF1K5EkJ9cD",
    apiKey: "quxSuw1kbVNPlp1RdfTwpNvKMQNUOIk4SnTKIZw8"
  },
  prod: {
    serverUrl: "https://duet-prod.herokuapp.com/1",
    appId: "fq8nKFTGz1GGlvTxURmzVRgScVrDNlwULy4Uzort",
    apiKey: "s9DDOuZoW8IDxIkWdKrZz4LAtUziE3PltLwemZ4P"
  }
};

// const devAppId = "cuM6t1j3fKQAKMKnRwdPp8m0KhnKNlF1K5EkJ9cD";
// const devApiKey = "quxSuw1kbVNPlp1RdfTwpNvKMQNUOIk4SnTKIZw8";

// const prodAppId = "fq8nKFTGz1GGlvTxURmzVRgScVrDNlwULy4Uzort";
// const prodApiKey = "s9DDOuZoW8IDxIkWdKrZz4LAtUziE3PltLwemZ4P";

// const devServerUrl = "https://duettransition.herokuapp.com/1";
// const prodServerUrl = "https://duetride.herokuapp.com/1";

Parse.initialize(
  env === "dev" || env === "preDev" ? config.dev.appId : config.prod.appId,
  env === "dev" || env === "preDev" ? config.dev.apiKey : config.prod.apiKey
);

// for now, since dev and preDev are using almost same configs except the heroku url,
// lets apply the changes in the config only, later on we must change all affected configs
Parse.serverURL =
  env === "dev"
    ? config.dev.serverUrl
    : env === "preDev"
    ? config.preDev.serverUrl
    : config.prod.serverUrl;

export default Parse;

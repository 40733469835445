import { all, takeEvery, put, call, select } from "redux-saga/effects";
import {
  fetchMapInfo,
  fetchTripListInfo,
  fetchLatestShuttleLocation
} from "services/map";
import { types } from "./actions";
import { types as commonTypes } from "../common/actions";

// export function* FETCH_MAP_INFO() {

//   yield put({
//     type: types.SET_STATE,
//     payload: {
//       loading: true
//     }
//   });
//   const response = yield call(fetchMapInfo);
//   if (response) {
//     const { location, polygon } = response;
//     const loc = { lat: location.latitude, lng: location.longtitude };

//     yield put({
//       type: types.SET_STATE,
//       payload: {
//         location: loc,
//         polygon
//       }
//     });
//   }
//   yield put({
//     type: types.SET_STATE,
//     payload: {
//       loading: false
//     }
//   });
// }

export function* FETCH_MAP_INFO() {
  // const getAuth = state => state.auth;
  // const auth = yield select(getAuth);

  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: true
    }
  });

  let response = false;

  // if (auth.employer_programs.length)
  response = yield call(fetchMapInfo);

  if (response.data.program_geofence_list.length > 0) {
    // const { location, polygon } = response;

    const lat = [];
    const lng = [];

    response.data.program_geofence_list.forEach(data => {
      data.geofence_data.forEach(element => {
        lat.push(element.latitude);
        lng.push(element.longitude);
      });
    });

    const latMax = Math.max(...lat);
    const lngMax = Math.max(...lng);
    const latMin = Math.min(...lat);
    const lngMin = Math.min(...lng);
    const latCenter = (latMax + latMin) / 2;
    const lngCenter = (lngMax + lngMin) / 2;
    const loc = { lat: latCenter, lng: lngCenter };

    const getToken = state => state.map;
    const token = yield select(getToken);

    if (token.loadingMap === 0) {
      yield put({
        type: types.SET_STATE,
        payload: {
          location: loc,
          polygon: response.data.program_geofence_list,
          loadingMap: 1,
          isDataReceive: true
        }
      });
    } else {
      yield put({
        type: types.SET_STATE,
        payload: {
          // location: loc,
          polygon: response.data.program_geofence_list,
          isDataReceive: true
        }
      });
    }
  } else {
    yield put({
      type: commonTypes.SET_STATE,
      payload: {
        isError: true,
        errContent: "Your service area geofence is missing."
      }
    });
    yield put({
      type: types.SET_STATE,
      payload: {
        isDataReceive: true
      }
    });
  }
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: false
    }
  });

  // const getToken = state => state.auth;
  // const token = yield select(getToken);

  // if (token.authorized === true) {
  yield put({
    type: types.FETCH_TRIP_LIST_INFO
  });

  yield put({
    type: types.FETCH_LATEST_SHUTTLE_LOCATION
  });
  // }
}

export function* FETCH_TRIP_LIST_INFO() {
  const response = yield call(fetchTripListInfo);
  // console.log(JSON.stringify(response));
  if (response.status === "success") {
    yield put({
      type: types.SET_STATE,
      payload: {
        tripList: response.data.trip_list
      }
    });
  } else {
    // yield put({
    //   type: commonTypes.SET_STATE,
    //   payload: {
    //     isError: true,
    //     errContent: response.message.message
    //   }
    // });
  }

  // yield delay(15000);

  // const getToken = state => state.auth;
  // const token = yield select(getToken);
  // console.log(token);
  // if (token.authorized === true)
  //   yield put({
  //     type: types.FETCH_TRIP_LIST_INFO
  //   });
}

export function* FETCH_LATEST_SHUTTLE_LOCATION() {
  const response = yield call(fetchLatestShuttleLocation);
  // console.log(response.data.shuttle_list);
  if (response.status === "success") {
    yield put({
      type: types.SET_STATE,
      payload: {
        latestShuttleLocation: response.data.shuttle_list
      }
    });
  } else {
    yield put({
      type: types.SET_STATE,
      payload: {
        latestShuttleLocation: []
      }
    });
  }

  // yield delay(15000);

  // const getToken = state => state.auth;
  // const token = yield select(getToken);

  // if (token.authorized === true)
  //   yield put({
  //     type: types.FETCH_LATEST_SHUTTLE_LOCATION
  //   });
}

export function* ZOOM_IN_OUT({ payload }) {
  yield put({
    type: types.SET_STATE,
    payload: {
      zoom: payload
    }
  });
}

export function* SET_CENTER({ payload }) {
  if (payload) {
    yield put({
      type: types.SET_STATE,
      payload: {
        location: payload
      }
    });
  }
}

export function* SET_SPINNER() {
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: true
    }
  });
}

export function* UNSET_SPINNER() {
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: false
    }
  });
}

export function* INIT_DATA_RECEIVE() {
  yield put({
    type: types.SET_STATE,
    payload: {
      isDataReceive: false
    }
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(types.FETCH_MAP_INFO, FETCH_MAP_INFO),
    takeEvery(types.FETCH_TRIP_LIST_INFO, FETCH_TRIP_LIST_INFO),
    takeEvery(types.ZOOM_IN_OUT, ZOOM_IN_OUT),
    takeEvery(types.SET_CENTER, SET_CENTER),
    takeEvery(types.SET_SPINNER, SET_SPINNER),
    takeEvery(types.UNSET_SPINNER, UNSET_SPINNER),
    takeEvery(types.INIT_DATA_RECEIVE, INIT_DATA_RECEIVE),
    takeEvery(
      types.FETCH_LATEST_SHUTTLE_LOCATION,
      FETCH_LATEST_SHUTTLE_LOCATION
    ),
    // FETCH_MAP_INFO() // run once on app load to get map info
    null
  ]);
}

/* eslint-disable camelcase */
import { reactLocalStorage } from "reactjs-localstorage";
import { all, takeEvery, put, call } from "redux-saga/effects";
import {
  fetchDriversList,
  createNewDriver,
  uploadImageToS3,
  fetchDriverInfoByID,
  fetchLabel,
  updateExistDriver,
  verifyUserPhone,
  sendPhoneConfirmation,
  createNewUser,
  updatePassword
} from "services/drivers";
import { types } from "./actions";
import { types as commonTypes } from "../common/actions";

export function* FETCH_DRIVERS_LIST() {
  // console.log(Date.now());
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: true
    }
  });

  const response = yield call(fetchDriversList);
  // console.log(JSON.stringify(response));
  if (response) {
    if (response.status === "success") {
      yield put({
        type: types.SET_STATE,
        payload: {
          driverList: response.data,
          newDriverStatus: null,
          newDriverId: null,
          existedDriverUpdate: false
        }
      });
    } else {
      yield put({
        type: commonTypes.SET_STATE,
        payload: {
          isError: true,
          errContent: response.message.message
        }
      });
    }
  }
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: false
    }
  });
}

export function* FETCH_LABEL() {
  const response = yield call(fetchLabel);

  if (response.status === 1) {
    if (response !== "Fleet not found!") {
      yield put({
        type: types.SET_STATE,
        payload: {
          label: response.data.fleet_name
        }
      });
    }
  } else {
    yield put({
      type: commonTypes.SET_STATE,
      payload: {
        isError: true,
        errContent: response.message.message
      }
    });
  }
}

export function* FETCH_DRIVER_INFO_BY_ID({ payload }) {
  const response = yield call(fetchDriverInfoByID, payload);

  if (response.status === "success") {
    yield put({
      type: types.SET_STATE,
      payload: {
        driverInfoById: response.data
      }
    });
  } else {
    yield put({
      type: commonTypes.SET_STATE,
      payload: {
        isError: true,
        errContent: response.message.message
      }
    });
  }
}

export function* UPDATE_EXIST_DRIVER({ payload }) {
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: true
    }
  });

  if (payload.driver_photo_replaced) {
    const respForS3 = yield call(uploadImageToS3, payload.photoObj);
    if (respForS3.status) {
      payload = { ...payload, driver_photo: respForS3.desc.Key };
    }
  } else {
    payload = { ...payload, driver_photo: "" };
  }

  if (payload.dl_photo_replaced) {
    const respDlForS3 = yield call(uploadImageToS3, payload.dl_photoObj);
    if (respDlForS3.status) {
      payload = { ...payload, dl_photo: respDlForS3.desc.Key };
    }
  } else {
    payload = { ...payload, dl_photo: "" };
  }

  const response = yield call(updateExistDriver, payload);
  // console.log(response);
  if (response.status === "success") {
    yield put({
      type: types.SET_STATE,
      payload: {
        existedDriverUpdate: true
      }
    });
  } else {
    yield put({
      type: commonTypes.SET_STATE,
      payload: {
        isError: true,
        errContent: response.message.message
      }
    });
  }

  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: false
    }
  });
}

export function* CREATE_NEW_DRIVER({ payload }) {
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: true
    }
  });

  reactLocalStorage.setObject("newUserInfo", {});

  if (payload.photo_Name !== "Choose file") {
    const respForS3 = yield call(uploadImageToS3, payload.photoObj);
    // console.log(respForS3);
    if (respForS3.status) {
      payload = {
        ...payload,
        driver_photo: respForS3.desc.Key
      };
    }
  } else {
    // eslint-disable-next-line no-underscore-dangle
    const env = window._env_.DEPLOYMENT_PROFILE;
    // let prefix = "";
    // if(env === "dev")
    //   prefix = "https://duettransition.herokuapp.com/1/files/cuM6t1j3fKQAKMKnRwdPp8m0KhnKNlF1K5EkJ9cD/";
    // else
    //   prefix = "https://duetride.herokuapp.com/1/files/fq8nKFTGz1GGlvTxURmzVRgScVrDNlwULy4Uzort/";

    const driver_photo =
      env === "dev" || env === "preDev"
        ? payload.photo.slice(86, payload.photo.length)
        : payload.photo.slice(80, payload.photo.length);
    // console.log(driver_photo);
    payload = {
      ...payload,
      driver_photo
    };
  }

  const response = yield call(createNewDriver, payload);

  if (response.status === "success") {
    yield put({
      type: types.SET_STATE,
      payload: {
        newDriverStatus: true,
        newDriverId: response.data.driver_id
      }
    });
  } else {
    yield put({
      type: types.SET_STATE,
      payload: {
        newDriverStatus: false
      }
    });

    // yield put({
    //   type: commonTypes.SET_STATE,
    //   payload: {
    //     isError: true,
    //     errContent: response.message.message
    //   }
    // });
  }

  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: false
    }
  });
}

export function* RESET_DRIVER_INFO() {
  yield put({
    type: types.SET_STATE,
    payload: {
      driverInfoById: {}
    }
  });
}

export function* CONFIRM_EXIST_USER_PHONE_NUMBER({ payload }) {
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: true
    }
  });

  const response = yield call(sendPhoneConfirmation, payload);

  if (response.status === "success") {
    yield put({
      type: types.SET_STATE,
      payload: {
        bExistEmail: true
      }
    });
  } else {
    // yield put({
    //   type: commonTypes.SET_STATE,
    //   payload: {
    //     isError: true,
    //     errContent: response.message.message
    //   }
    // });

    yield put({
      type: types.SET_STATE,
      payload: {
        bExistEmail: false
      }
    });
  }

  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: false
    }
  });
}

export function* UPDATE_PASSWORD({ payload }) {
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: true
    }
  });

  const response = yield call(updatePassword, payload);

  if (response.status === "success") {
    yield put({
      type: types.SET_STATE,
      payload: {
        isPasswordUpdate: true,
        existedDriverUpdate: true
      }
    });
  } else {
    yield put({
      type: commonTypes.SET_STATE,
      payload: {
        isError: true,
        errContent: response.message
      }
    });
  }

  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: false
    }
  });
}

export function* CONFIRM_NEW_USER_PHONE_NUMBER({ payload }) {
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: true
    }
  });

  const response = yield call(sendPhoneConfirmation, payload);

  if (response.status === "success") {
    yield put({
      type: types.SET_STATE,
      payload: {
        bNewEmail: false
      }
    });
  } else {
    // console.log(response.message.message);
    // console.log(response.message);

    // eslint-disable-next-line no-lonely-if
    if (response.message.error_code === "PHONENOTFOUND") {
      yield put({
        type: types.SET_STATE,
        payload: {
          bNewEmail: true,
          bErrorForNewEmail: false
        }
      });
    } else {
      yield put({
        type: types.SET_STATE,
        payload: {
          bNewEmail: true,
          bErrorForNewEmail: true,
          sErrorMsgForNewEmail: response.message.message
        }
      });
    }
  }

  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: false
    }
  });
}

export function* CONFIRM_VERIFICATION_CODE({ payload }) {
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: true
    }
  });

  const response = yield call(verifyUserPhone, payload);
  // console.log(response);
  if (response.status === "success") {
    reactLocalStorage.setObject("newUserInfo", response.data);
    yield put({
      type: types.SET_STATE,
      payload: {
        bVerificationCodeError: false,
        userObj: response.data
      }
    });
  } else {
    yield put({
      type: types.SET_STATE,
      payload: {
        bVerificationCodeError: true,
        userObj: {}
      }
    });
  }

  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: false
    }
  });
}

export function* RESET_NEW_USER_STATUS() {
  // console.log("reset new user status");
  yield put({
    type: types.SET_STATE,
    payload: {
      bExistEmail: null,
      bNewEmail: null,
      bErrorForNewEmail: null,
      sErrorMsgForNewEmail:
        "We are having problem of sending a confirmation to this number. Please check the number entered.",
      bVerificationCodeError: null
    }
  });
}

export function* CREATE_NEW_USER({ payload }) {
  reactLocalStorage.setObject("newUserInfo", {});

  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: true
    }
  });

  const { firstname, lastname, phone, email } = payload;

  const realParams = {
    firstname,
    lastname,
    phone,
    email
  };
  // console.log(JSON.stringify(realParams));
  const response = yield call(createNewUser, realParams);
  // console.log(JSON.stringify(response));
  if (response.status === "success") {
    reactLocalStorage.setObject("newUserInfo", response.data);
    yield put({
      type: types.SET_STATE,
      payload: {
        userObj: response.data,
        bVerificationCodeError: false,
        goToNewDriverPage: true
      }
    });
  } else {
    yield put({
      type: commonTypes.SET_STATE,
      payload: {
        isError: true,
        errContent: response.message.message
      }
    });
  }

  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: false
    }
  });
}

export function INIT_LOCAL_STORAGE_NEW_USER_INFO() {
  reactLocalStorage.setObject("newUserInfo", {});
}

export default function* rootSaga() {
  yield all([
    takeEvery(types.FETCH_DRIVERS_LIST, FETCH_DRIVERS_LIST),
    takeEvery(types.CREATE_NEW_DRIVER, CREATE_NEW_DRIVER),
    takeEvery(types.FETCH_LABEL, FETCH_LABEL),
    takeEvery(types.UPDATE_EXIST_DRIVER, UPDATE_EXIST_DRIVER),
    takeEvery(types.RESET_DRIVER_INFO, RESET_DRIVER_INFO),
    takeEvery(
      types.CONFIRM_EXIST_USER_PHONE_NUMBER,
      CONFIRM_EXIST_USER_PHONE_NUMBER
    ),
    takeEvery(
      types.CONFIRM_NEW_USER_PHONE_NUMBER,
      CONFIRM_NEW_USER_PHONE_NUMBER
    ),
    takeEvery(types.CONFIRM_VERIFICATION_CODE, CONFIRM_VERIFICATION_CODE),
    takeEvery(types.FETCH_DRIVER_INFO_BY_ID, FETCH_DRIVER_INFO_BY_ID),
    takeEvery(types.CREATE_NEW_USER, CREATE_NEW_USER),
    takeEvery(types.RESET_NEW_USER_STATUS, RESET_NEW_USER_STATUS),
    takeEvery(types.UPDATE_PASSWORD, UPDATE_PASSWORD),
    takeEvery(
      types.INIT_LOCAL_STORAGE_NEW_USER_INFO,
      INIT_LOCAL_STORAGE_NEW_USER_INFO
    ),
    null
  ]);
}

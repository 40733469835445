import { createReducer } from "reduxsauce";

import { types } from "./actions";

const INITIAL_STATE = {
  location: {
    lat: 0,
    lng: 0
  },
  polygon: [],
  loading: false,
  tripList: [],
  latestShuttleLocation: [],
  zoom: 10,
  loadingMap: 0,
  isDataReceive: false
};

export default createReducer(INITIAL_STATE, {
  [types.SET_STATE]: (state, { payload }) => ({ ...state, ...payload })
});

/* eslint-disable */
import React, { PureComponent } from "react";
import { Route, Switch, Redirect } from "react-router";
import { ConnectedRouter } from "connected-react-router";
import Loadable from "react-loadable";
import { connect } from "react-redux";
import Loader from "./components/LayoutComponents/Loader";
import NotFound from "./pages/404";
import IndexLayout from "./layouts";
// import ODMapPage from "./pages/odmapdisplay";
// import HistoryDisplay from "./pages/history";
// import bookingHistoryView from "./pages/history/viewBookingInfo";

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />
  });

const routes = [
  // Auth pages
  {
    path: "/auth/login",
    // component: Login,
    component: loadable(() => import("./pages/auth/login")),
    privilege: [],
    exact: true
  },
  {
    path: "/auth/signup",
    // component: Signup,
    component: loadable(() => import("./pages/auth/signup")),
    privilege: [],
    exact: true
  },
  {
    path: "/auth/forgotpassword",
    // component: Signup,
    component: loadable(() => import("./pages/auth/forgotPassword")),
    privilege: [],
    exact: true
  },

  // Dashboard
  {
    path: "/dashboard",
    component: loadable(() => import("./pages/dashboard")),
    privilege: [1, 3],
    exact: true
  },
  {
    path: "/dashboard/task-panel",
    component: loadable(() => import("./pages/dashboard/taskPanel")),
    privilege: [1, 3],
    exact: true
  },
  {
    path: "/dashboard/change/:booking_id/:trip_id",
    component: loadable(() => import("./pages/dashboard/change")),
    privilege: [1, 3],
    exact: true
  },
  {
    path: "/dashboard/bookings-view/:id",
    component: loadable(() => import("./pages/dashboard/viewBookingInfo")),
    privilege: [1, 3],
    exact: true
  },
  {
    path: "/reports/graphs",
    component: loadable(() => import("./pages/reports")),
    privilege: [3, 4],
    exact: true
  },
  {
    path: "/reports/history/view/:id",
    component: loadable(() => import("./pages/history/viewBookingInfo")),
    privilege: [3, 4],
    exact: true
  },
  {
    path: "/reports/history/changePaymentStatus/:booking_id",
    component: loadable(() => import("./pages/history/changePaymentStatus")),
    privilege: [1, 2],
    exact: true
  },
  {
    path: "/reports/map",
    component: loadable(() => import("./pages/odmapdisplay")),
    privilege: [3, 4],
    exact: true
  },
  {
    path: "/reports/history",
    component: loadable(() => import("./pages/history")),
    privilege: [3, 4],
    exact: true
  },
  {
    path: "/blank",
    component: loadable(() => import("./pages/blank")),
    privilege: [],
    exact: true
  },
  // Fleet/Vehicles
  {
    path: "/fleet/vehicles",
    component: loadable(() => import("./pages/fleet/vehicles")),
    privilege: [3, 4],
    exact: true
  },
  {
    path: "/fleet/vehicles/add",
    component: loadable(() => import("./pages/fleet/vehicles/newVehicle")),
    privilege: [3, 4],
    exact: true
  },
  {
    path: "/fleet/vehicles/view/:id",
    component: loadable(() => import("./pages/fleet/vehicles/viewVehicleInfo")),
    privilege: [3, 4],
    exact: true
  },
  {
    path: "/fleet/vehicles/modify/:id",
    component: loadable(() =>
      import("./pages/fleet/vehicles/modifyVehicleInfo")
    ),
    privilege: [3, 4],
    exact: true
  },
  // Fleet/drivers
  {
    path: "/fleet/drivers",
    component: loadable(() => import("./pages/fleet/drivers")),
    privilege: [3, 4],
    exact: true
  },
  {
    path: "/fleet/drivers/list",
    component: loadable(() => import("./pages/fleet/drivers")),
    privilege: [3, 4],
    exact: true
  },
  {
    path: "/fleet/drivers/prepare",
    component: loadable(() => import("./pages/fleet/drivers/preNewUserCreate")),
    privilege: [3, 4],
    exact: true
  },
  {
    path: "/fleet/drivers/new",
    component: loadable(() => import("./pages/fleet/drivers/newDrivers")),
    privilege: [3, 4],
    exact: true
  },
  {
    path: "/fleet/drivers/view/:id",
    component: loadable(() => import("./pages/fleet/drivers/viewDriverInfo")),
    privilege: [3, 4],
    exact: true
  },
  {
    path: "/fleet/drivers/modify/:id",
    component: loadable(() => import("./pages/fleet/drivers/modifyDriverInfo")),
    privilege: [3, 4],
    exact: true
  },
  {
    path: "/fleet/trips",
    component: loadable(() => import("./pages/fleet/trips")),
    privilege: [3, 4],
    exact: true
  },
  {
    path: "/fleet/drivers/schedule",
    component: loadable(() => import("./pages/fleet/drivers/schedule")),
    privilege: [3, 4],
    exact: true
  },
  {
    path: "/fleet/dispatch",
    component: loadable(() => import("./pages/fleet/drivers/dispatch")),
    privilege: [3, 4],
    exact: true
  },
  {
    path: "/fleet/dispatchtemplate",
    component: loadable(() => import("./pages/fleet/drivers/templateIndex")),
    privilege: [3, 4],
    exact: true
  },
  {
    path: "/fleet/dispatchtemplate/view/templateId/:id",
    component: loadable(() =>
      import("./pages/fleet/drivers/savedTemplateDispatch")
    ),
    privilege: [3, 4],
    exact: true
  },
  {
    path: "/fleet/subscription",
    component: loadable(() => import("./pages/fleet/subscription")),
    privilege: [3, 4],
    exact: true
  },
  {
    path: "/fleet/subscription/view/:id",
    component: loadable(() =>
      import("./pages/fleet/subscription/viewSubscriptionInfo")
    ),
    privilege: [3, 4],
    exact: true
  },
  // rider service
  {
    path: "/rider-services/bookings",
    component: loadable(() => import("./pages/rider-services/bookings")),
    privilege: [1, 2],
    exact: true
  },
  {
    path: "/rider-services/bookings/futurebookinglist",
    component: loadable(() =>
      import("./pages/rider-services/bookings/futureBookingList")
    ),
    privilege: [1, 2],
    exact: true
  },
  {
    path: "/rider-services/bookings/prepareNew",
    component: loadable(() =>
      import("./pages/rider-services/bookings/prepareNew")
    ),
    privilege: [1, 2],
    exact: true
  },
  {
    path: "/rider-services/bookings/newBooking",
    component: loadable(() =>
      import("./pages/rider-services/bookings/newBooking")
    ),
    privilege: [1, 2],
    exact: true
  },
  {
    path: "/rider-services/bookings/assess",
    component: loadable(() => import("./pages/rider-services/bookings/assess")),
    privilege: [1, 2],
    exact: true
  },
  {
    path: "/rider-services/bookings/assess/first-trip",
    component: loadable(() =>
      import("./pages/rider-services/bookings/firsttrip")
    ),
    privilege: [1, 2],
    exact: true
  },
  // NEM Transport /rider-services/nem-transport/newInsurance
  {
    path: "/rider-services/nem-transport",
    component: loadable(() =>
      import("./pages/rider-services/nem-transport/listIndex")
    ),
    privilege: [1, 2],
    exact: true
  },
  {
    path: "/rider-services/nem-transport/selectInsurance",
    component: loadable(() => import("./pages/rider-services/nem-transport")),
    privilege: [1, 2],
    exact: true
  },
  {
    path: "/rider-services/nem-transport/newInsurance",
    component: loadable(() =>
      import("./pages/rider-services/nem-transport/newInsurance")
    ),
    privilege: [1, 2],
    exact: true
  },
  {
    path: "/rider-services/nem-transport/fileUpload/:id",
    component: loadable(() =>
      import("./pages/rider-services/nem-transport/fileUpload")
    ),
    privilege: [1, 2],
    exact: true
  },

  // {
  //   path: "/rider-services/bookings/prepareNew",
  //   component: loadable(() =>
  //     import("./pages/rider-services/bookings/prepareNew")
  //   ),
  //   exact: true
  // },
  {
    exact: true,
    path: "/rider-services/bookings/view/:id/:historySource?",
    component: loadable(() =>
      import("./pages/rider-services/bookings/viewBookingInfo")
    ),
    privilege: [1, 2]
  },
  {
    path: "/rider-services/bookings/newsubscription/:id",
    component: loadable(() =>
      import("./pages/rider-services/bookings/newSubscription")
    ),
    privilege: [1, 2],
    exact: true
  },
  {
    path: "/rider-services/bookings/change/:id",
    component: loadable(() =>
      import("./pages/rider-services/bookings/prepareChange")
    ),
    privilege: [1, 2],
    exact: true
  },
  {
    path:
      "/rider-services/bookings/modify/:booking_id/:passenger_id/:historySource?",
    component: loadable(() =>
      import("./pages/rider-services/bookings/modifyBookingInfo")
    ),
    privilege: [1, 2],
    exact: true
  },
  {
    path: "/rider-services/bookings/changePaymentStatus/:booking_id",
    component: loadable(() =>
      import("./pages/rider-services/bookings/changePaymentStatus")
    ),
    privilege: [1, 2],
    exact: true
  },
  {
    path: "/rider-services/members",
    component: loadable(() => import("./pages/rider-services/members")),
    privilege: [1, 2],
    exact: true
  },
  {
    path: "/rider-services/members/prepareNew",
    component: loadable(() =>
      import("./pages/rider-services/members/prepareNew")
    ),
    privilege: [1, 2],
    exact: true
  },
  {
    path: "/rider-services/members/view/:id",
    component: loadable(() =>
      import("./pages/rider-services/members/viewMemberInfo")
    ),
    privilege: [1, 2],
    exact: true
  },
  {
    path: "/rider-services/members/modify/:user_id",
    component: loadable(() =>
      import("./pages/rider-services/members/modifyMemberInfo")
    ),
    privilege: [1, 2],
    exact: true
  },
  // programs/services
  {
    path: "/programs/services",
    component: loadable(() => import("./pages/programs/services")),
    privilege: [3, 4],
    exact: true
  },
  // Account Management
  {
    path: "/account_mngm/users",
    component: loadable(() => import("./pages/account-mngm/users")),
    privilege: [3, 4],
    exact: true
  },
  {
    path: "/account_mngm/plan-billing",
    component: loadable(() => import("./pages/account-mngm/billing")),
    privilege: [3, 4],
    exact: true
  },
  {
    path: "/account_mngm/payment-method",
    component: loadable(() => import("./pages/account-mngm/payment")),
    privilege: [3, 4],
    exact: true
  },
  {
    path: "/account_mngm/payment-method/stripe-payment",
    component: loadable(() =>
      import("./pages/account-mngm/payment/stripePayment")
    ),
    privilege: [3, 4],
    exact: true
  },
  // contact info
  {
    path: "/contact/contact-info",
    component: loadable(() => import("./pages/contact")),
    privilege: [],
    exact: true
  }
];

@connect(({ auth }) => ({ auth }))
class Router extends PureComponent {
  generateRoute = route => {
    const { auth } = this.props;

    if (!auth.authorized || route.privilege.length === 0)
      return (
        <Route
          path={route.path}
          component={route.component}
          key={route.path}
          exact={route.exact}
        />
      );

    for (let index = 0; index < route.privilege.length; index++) {
      const element = route.privilege[index];
      if (auth.is_admin.includes(element)) {
        return (
          <Route
            path={route.path}
            component={route.component}
            key={route.path}
            exact={route.exact}
          />
        );
        break;
      }
    }
  };

  render() {
    const { history, auth } = this.props;

    return (
      <ConnectedRouter history={history}>
        <IndexLayout>
          <Switch>
            <Route
              exact
              path="/"
              key="/"
              render={() => <Redirect to="/dashboard" />}
            />
            {routes.map(route =>
              // <Route
              //   path={route.path}
              //   component={route.component}
              //   key={route.path}
              //   exact={route.exact}
              // />
              this.generateRoute(route)
            )}

            {/* <Route path="/reports/map" component={ODMapPage}  /> */}
            {/* <Route path="/reports/history" component={HistoryDisplay} /> */}
            {/* <Route path="/reports/history/view/:id" component={bookingHistoryView} /> */}

            <Route component={NotFound} />
          </Switch>
        </IndexLayout>
      </ConnectedRouter>
    );
  }
}

export default Router;

import { createActions } from "reduxsauce";

const { Types, Creators } = createActions(
  {
    setState: ["payload"],
    fetchDriversList: null,
    createNewDriver: ["payload"],
    updateExistDriver: ["payload"],
    fetchDriverInfoById: ["payload"],
    fetchLabel: null,
    resetDriverInfo: null,
    confirmExistUserPhoneNumber: ["payload"],
    confirmNewUserPhoneNumber: ["payload"],
    confirmVerificationCode: ["payload"],
    resetNewUserStatus: null,
    createNewUser: ["payload"],
    updatePassword: ["payload"],
    initLocalStorageNewUserInfo: null
  },
  { prefix: "DRIVER_" }
);

export const types = Types;
export default Creators;

/* eslint-disable camelcase */
import { all, takeEvery, put, call } from "redux-saga/effects";
import {
  fetchBookingHistoryList,
  fetchBookingInfoByID,
  fetctUserProgramName,
  getDailyRideshipData
} from "../../../services/reports/history";
import { types } from "./actions";
import { types as commonTypes } from "../../common/actions";

export function* FETCH_BOOKING_HISTORY_LIST({ payload }) {
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: true
    }
  });

  const response = yield call(fetchBookingHistoryList, payload);
  // console.log(response);
  if (response) {
    if (response.status === "success") {
      yield put({
        type: types.SET_STATE,
        payload: {
          List: response.data.booking_history
        }
      });
    } else {
      yield put({
        type: commonTypes.SET_STATE,
        payload: {
          isError: true,
          errContent: response.message.message
        }
      });
    }
  }

  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: false
    }
  });
}

export function* FETCH_USER_PROGRAM_NAME() {
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: true
    }
  });

  const response = yield call(fetctUserProgramName);

  if (response) {
    if (response.status === "success") {
      yield put({
        type: types.SET_STATE,
        payload: {
          programList: response.data.programs
        }
      });
    } else {
      yield put({
        type: commonTypes.SET_STATE,
        payload: {
          isError: true,
          errContent: response.message.message
        }
      });
    }
  }

  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: false
    }
  });
}

export function* FETCH_BOOKING_INFO_BY_ID({ payload }) {
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: true
    }
  });

  const response = yield call(fetchBookingInfoByID, payload);
  if (response.status === "success") {
    yield put({
      type: types.SET_STATE,
      payload: {
        InfoById: response.data
      }
    });
  } else {
    yield put({
      type: commonTypes.SET_STATE,
      payload: {
        isError: true,
        errContent: response.message.message
      }
    });
  }

  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: false
    }
  });
}

export function* GET_DAILY_RIDESHIP_DATA({ payload }) {
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: true
    }
  });

  const response = yield call(getDailyRideshipData, payload);
  // console.log(response);
  if (response.status === "success") {
    yield put({
      type: types.SET_STATE,
      payload: {
        graphData: response.data.ridership_data
      }
    });
  } else {
    yield put({
      type: commonTypes.SET_STATE,
      payload: {
        isError: true,
        errContent: response.message.message
      }
    });
  }

  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: false
    }
  });
}

export function* RESET_BOOKING_HISTORY_LIST() {
  yield put({
    type: types.SET_STATE,
    payload: {
      List: []
    }
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(types.FETCH_BOOKING_HISTORY_LIST, FETCH_BOOKING_HISTORY_LIST),
    takeEvery(types.FETCH_USER_PROGRAM_NAME, FETCH_USER_PROGRAM_NAME),
    takeEvery(types.FETCH_BOOKING_INFO_BY_ID, FETCH_BOOKING_INFO_BY_ID),
    takeEvery(types.RESET_BOOKING_HISTORY_LIST, RESET_BOOKING_HISTORY_LIST),
    takeEvery(types.GET_DAILY_RIDESHIP_DATA, GET_DAILY_RIDESHIP_DATA),
    null
  ]);
}

/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import aws from "aws-sdk";
import parse from "../_parse";
// import "../../config";

require("dotenv").config();

const env = window._env_.DEPLOYMENT_PROFILE;

// aws.config.update({
//   region: env === "dev" ? devRegion : prodRegion,
//   accessKeyId: env === "dev" ? devAccessKeyId : prodAccessKeyId,
//   secretAccessKey: env === "dev" ? devSecretAccessKey : prodSecretAccessKey
// });
aws.config.update({
  region:
    env === "dev" || env === "preDev"
      ? process.env.REACT_APP_DevRegion
      : process.env.REACT_APP_ProdRegion,
  accessKeyId:
    env === "dev" || env === "preDev"
      ? process.env.REACT_APP_DevAccessKeyId
      : process.env.REACT_APP_ProdAccessKeyId,
  secretAccessKey:
    env === "dev" || env === "preDev"
      ? process.env.REACT_APP_DevSecretAccessKey
      : process.env.REACT_APP_ProdSecretAccessKey
});
const s3 = new aws.S3();

export async function fetchBookingsList(param) {
  try {
    const lists = await parse.Cloud.run("v1_webapp_get_booking_list", param);
    // console.log(lists);
    return lists;
  } catch (error) {
    console.log("fetchBookingsList() = ", JSON.stringify(error));
    return error;
  }
}

export async function getBookingAssessmentData(param) {
  try {
    const lists = await parse.Cloud.run(
      "v1_webapp_get_booking_assessment_data",
      param
    );
    // console.log(lists);
    return lists;
  } catch (error) {
    console.log("getBookingAssessmentData() = ", JSON.stringify(error));
    return error;
  }
}

export async function generateFirstTrip(param) {
  try {
    const lists = await parse.Cloud.run("v1_webapp_generate_first_trip", param);
    // console.log(lists);
    return lists;
  } catch (error) {
    console.log("generateFirstTrip() = ", JSON.stringify(error));
    return error;
  }
}

export async function getNumOutstandingBookings(param) {
  try {
    const lists = await parse.Cloud.run(
      "v1_webapp_get_num_outstanding_bookings",
      param
    );
    // console.log(lists);
    return lists;
  } catch (error) {
    console.log("getNumOutstandingBookings() = ", JSON.stringify(error));
    return error;
  }
}

export async function getFirstTripProgramsData() {
  try {
    const lists = await parse.Cloud.run(
      "v1_webapp_get_first_trip_programs_data"
    );
    // console.log(lists);
    return lists;
  } catch (error) {
    console.log("getFirstTripProgramsData() = ", JSON.stringify(error));
    return error;
  }
}

export async function fetchLabel() {
  try {
    const data = await parse.Cloud.run("v1_webapp_get_user_fleet_name");
    return { status: 1, data };
  } catch (error) {
    console.log("fetchLabel() = ", JSON.stringify(error));
    return { status: 0, data: error };
  }
}

export async function recreateBooking(param) {
  try {
    const data = await parse.Cloud.run(
      "v1_webapp_recreate_cancelled_booking",
      param
    );
    return data;
  } catch (error) {
    console.log("recreateBooking() = ", JSON.stringify(error));
    return error;
  }
}

export async function uploadImageToS3(params) {
  const s3Params = {
    // Bucket: env === "dev" ? devBucket : prodBucket,
    Bucket:
      env === "dev" || env === "preDev"
        ? process.env.REACT_APP_DevBucket
        : process.env.REACT_APP_ProdBucket,
    Body: params,
    Key: params.name,
    Expires: 500,
    ContentType: params.type,
    ACL: "public-read"
  };

  // console.log(s3Params);

  return new Promise(resolve => {
    // eslint-disable-next-line func-names
    s3.upload(s3Params, function(err, data) {
      if (err) {
        // console.log("file upload fail");
        // console.log(err, err.stack); // an error occurred
        // console.log(JSON.stringify(err));
        resolve({ status: false, desc: err });
      } else {
        // console.log("file upload success", JSON.stringify(data));
        resolve({ status: true, desc: data });
      }
    });
  });
}

export async function fetchBookingInfoByID(param) {
  try {
    const response = await parse.Cloud.run(
      "v1_webapp_get_booking_info_by_id",
      param
    );
    // console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log("fetchBookingInfoByID = ", JSON.stringify(error));
    return error;
  }
}

export async function checkValidateBookingEditTime(param) {
  try {
    const response = await parse.Cloud.run(
      "v1_webapp_validate_booking_edit_time",
      param
    );
    // console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log(
      "Service bookings::checkValidateBookingEditTime = ",
      JSON.stringify(error)
    );
    return error;
  }
}

export async function sendRequestPhoneVerifyCode(param) {
  try {
    const response = await parse.Cloud.run(
      "v1_webapp_send_phone_confirmation",
      param
    );
    // console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log(
      "Service bookings::sendRequestPhoneVerifyCode = ",
      JSON.stringify(error)
    );
    return error;
  }
}

export async function verifyUserPhone(param) {
  try {
    const response = await parse.Cloud.run(
      "v1_webapp_verify_user_phone",
      param
    );
    // console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log("Service bookings::VerifyUserPhone = ", JSON.stringify(error));
    return error;
  }
}

export async function verifyBookingProgram(param) {
  try {
    const response = await parse.Cloud.run(
      "v1_webapp_verify_booking_program",
      param
    );
    // console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log(
      "Service bookings::verifyBookingProgram = ",
      JSON.stringify(error)
    );
    return error;
  }
}

export async function getAccessiblePrograms(param) {
  try {
    const response = await parse.Cloud.run(
      "v1_webapp_get_accessible_programs",
      param
    );
    // console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log(
      "Service bookings::getAccessiblePrograms = ",
      JSON.stringify(error)
    );
    return error;
  }
}

export async function getAvailablePrograms() {
  try {
    const response = await parse.Cloud.run("v1_webapp_get_available_programs");
    // console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log(
      "Service bookings::getAvailablePrograms = ",
      JSON.stringify(error)
    );
    return error;
  }
}

export async function getProgramData(param) {
  try {
    const response = await parse.Cloud.run("v1_webapp_get_program_data", param);
    // console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log("Service bookings::getProgramData = ", JSON.stringify(error));
    return error;
  }
}

export async function getFleetInsurance() {
  try {
    const response = await parse.Cloud.run("v1_webapp_get_fleet_insurance");
    // console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log(
      "Service bookings::getFleetInsurance = ",
      JSON.stringify(error)
    );
    return error;
  }
}

export async function getMobilityAssistance() {
  try {
    const response = await parse.Cloud.run(
      "v1_webapp_get_mobility_assistance_data"
    );
    // console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log(
      "Service bookings::getMobilityAssistance = ",
      JSON.stringify(error)
    );
    return error;
  }
}

export async function getAvailableBookingDates(param) {
  try {
    const response = await parse.Cloud.run(
      "v1_webapp_get_available_booking_dates",
      param
    );
    // console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log(
      "Service bookings::getAvailableBookingDates = ",
      JSON.stringify(error)
    );
    return error;
  }
}

export async function getEstimatedBookingFee(param) {
  try {
    const response = await parse.Cloud.run(
      "v1_webapp_get_estimated_booking_fee",
      param
    );
    // console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log(
      "Service bookings::getEstimatedBookingFee = ",
      JSON.stringify(error)
    );
    return error;
  }
}

export async function getProgramPaymentMethods(param) {
  try {
    const response = await parse.Cloud.run(
      "v1_webapp_get_program_payment_methods",
      param
    );
    // console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log(
      "Service bookings::getProgramPaymentMethods = ",
      JSON.stringify(error)
    );
    return error;
  }
}

export async function initiatePassengerStripePayment(param) {
  try {
    const response = await parse.Cloud.run(
      "v1_webapp_initiate_passenger_stripe_payment",
      param
    );
    // console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log(
      "Service bookings::initiatePassengerStripePayment = ",
      JSON.stringify(error)
    );
    return error;
  }
}

export async function getPassengerPaymentInfo(param) {
  try {
    const response = await parse.Cloud.run(
      "v1_webapp_get_passenger_payment_info",
      param
    );
    // console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log(
      "Service bookings::getPassengerPaymentInfo = ",
      JSON.stringify(error)
    );
    return error;
  }
}

export async function initiateBookingPayment(param) {
  try {
    const response = await parse.Cloud.run(
      "v1_webapp_initiate_booking_payment",
      param
    );
    // console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log(
      "Service bookings::initiateBookingPayment = ",
      JSON.stringify(error)
    );
    return error;
  }
}

export async function verifyBookingPayment(param) {
  try {
    const response = await parse.Cloud.run(
      "v1_webapp_verify_booking_payment",
      param
    );
    console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log(
      "Service bookings::verifyBookingPayment = ",
      JSON.stringify(error)
    );
    return error;
  }
}

export async function createNewBooking(param) {
  try {
    const response = await parse.Cloud.run(
      "v1_webapp_create_new_booking",
      param
    );
    // console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log("Service bookings::createNewBooking = ", JSON.stringify(error));
    return error;
  }
}

export async function createNewRiderUser(param) {
  try {
    const response = await parse.Cloud.run(
      "v1_webapp_create_new_rider_user",
      param
    );
    // console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log(
      "Service bookings::createNewRiderUser = ",
      JSON.stringify(error)
    );
    return error;
  }
}

export async function cancelBooking(param) {
  try {
    const response = await parse.Cloud.run("v1_webapp_cancel_booking", param);
    // console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log("Service bookings::cancelBooking = ", JSON.stringify(error));
    return error;
  }
}

export async function editBooking(param) {
  try {
    const response = await parse.Cloud.run("v1_webapp_edit_booking", param);
    // console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log("Service bookings::editBooking = ", JSON.stringify(error));
    return error;
  }
}

export async function populateBookingEditTime(param) {
  try {
    const response = await parse.Cloud.run(
      "v1_webapp_populate_booking_edit_form",
      param
    );
    // console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log(
      "Service bookings::populateBookingEditTime = ",
      JSON.stringify(error)
    );
    return error;
  }
}

export async function updateBookingPaymentStatus(param) {
  try {
    const response = await parse.Cloud.run(
      "v1_webapp_update_booking_payment_status",
      param
    );
    // console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log(
      "Service bookings::updateBookingPaymentStatus = ",
      JSON.stringify(error)
    );
    return error;
  }
}

export async function editAdminNote(param) {
  try {
    const response = await parse.Cloud.run("v1_webapp_edit_admin_note", param);
    return response;
  } catch (error) {
    console.log("Service bookings::editAdminNote = ", error);
    return error;
  }
}

export async function replicateBooking(param) {
  try {
    const response = await parse.Cloud.run(
      "v1_webapp_replicate_booking",
      param
    );
    return response;
  } catch (error) {
    console.log("Service bookings::replicateBooking = ", error);
    return error;
  }
}

export async function createNewFleetSubscription(param) {
  try {
    const lists = await parse.Cloud.run(
      "v1_webapp_create_new_fleet_subscription",
      param
    );
    // console.log(lists);
    return lists;
  } catch (error) {
    console.log("createNewFleetSubscription() = ", JSON.stringify(error));
    return error;
  }
}

export async function getFleetSubscriptionList(param) {
  try {
    const lists = await parse.Cloud.run(
      "v1_webapp_get_fleet_subscription_list",
      param
    );
    // console.log(lists);
    return lists;
  } catch (error) {
    console.log("getFleetSubscriptionList() = ", JSON.stringify(error));
    return error;
  }
}

export async function getFleetSubscriptionData(param) {
  try {
    const lists = await parse.Cloud.run(
      "v1_webapp_get_fleet_subscription_data",
      param
    );
    // console.log(lists);
    return lists;
  } catch (error) {
    console.log("getFleetSubscriptionData() = ", JSON.stringify(error));
    return error;
  }
}

export async function editFleetSubscriptionNote(param) {
  try {
    const lists = await parse.Cloud.run(
      "v1_webapp_edit_fleet_subscription_note",
      param
    );
    // console.log(lists);
    return lists;
  } catch (error) {
    console.log("getFleetSubscriptionData() = ", JSON.stringify(error));
    return error;
  }
}

export async function deleteFleetSubscription(param) {
  try {
    const lists = await parse.Cloud.run(
      "v1_webapp_delete_fleet_subscription",
      param
    );
    // console.log(lists);
    return lists;
  } catch (error) {
    console.log("deleteFleetSubscription() = ", JSON.stringify(error));
    return error;
  }
}

export async function editFleetSubscription(param) {
  try {
    const lists = await parse.Cloud.run(
      "v1_webapp_edit_fleet_subscription",
      param
    );
    // console.log(lists);
    return lists;
  } catch (error) {
    console.log("editFleetSubscription() = ", JSON.stringify(error));
    return error;
  }
}

export async function searchUserByName(param) {
  try {
    const lists = await parse.Cloud.run("v1_webapp_search_user_by_name", param);
    // console.log(lists);
    return lists;
  } catch (error) {
    console.log("searchUserByName() = ", JSON.stringify(error));
    return error;
  }
}

export async function searchUserByPhone(param) {
  try {
    const lists = await parse.Cloud.run(
      "v1_webapp_search_user_by_phone",
      param
    );
    // console.log(lists);
    return lists;
  } catch (error) {
    console.log("searchUserByPhone() = ", JSON.stringify(error));
    return error;
  }
}

export async function getUserLatestBookings(param) {
  try {
    const lists = await parse.Cloud.run(
      "v1_webapp_get_user_latest_bookings",
      param
    );
    // console.log(lists);
    return lists;
  } catch (error) {
    console.log("getUserLatestBookings() = ", JSON.stringify(error));
    return error;
  }
}

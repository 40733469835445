// import request from "./_request";
import parse from "./_parse";

// export async function fetchMapInfo() {
//   const resp = await request({
//     method: "get",
//     url: "/geofence"
//   });

//   return resp.data;
// }

export async function fetchTripListInfo() {
  try {
    const lists = await parse.Cloud.run("v1_webapp_get_trip_list_info");
    // console.log("get_trip_list_info = ", JSON.stringify(lists));
    return lists;
  } catch (error) {
    console.log(JSON.stringify(error));
    return error;
  }
}

export async function fetchLatestShuttleLocation() {
  try {
    const data = await parse.Cloud.run("v1_webapp_get_latest_shuttle_location");
    // console.log("get_latest_shuttle_location = ", JSON.stringify(data));
    return data;
  } catch (error) {
    console.log(JSON.stringify(error));
    return error;
  }
}

export async function fetchMapInfo(params) {
  try {
    const data = await parse.Cloud.run("v1_webapp_get_geofence_data", params);
    // console.log(data);
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
}

// export default { fetchMapInfo, fetchTripListInfo };

/* eslint-disable camelcase */
import parse from "../_parse";

export async function fetchBookingHistoryList(param) {
  try {
    const lists = await parse.Cloud.run("v1_webapp_get_booking_history", param);
    // console.log(lists);
    return lists;
  } catch (error) {
    console.log("reports/fetchBookingHistoryList() = ", JSON.stringify(error));
    return error;
  }
}

export async function fetctUserProgramName() {
  try {
    const lists = await parse.Cloud.run("v1_webapp_get_user_program_name");
    // console.log(lists);
    return lists;
  } catch (error) {
    console.log("reports/fetctUserProgramName() = ", JSON.stringify(error));
    return error;
  }
}

export async function fetchBookingInfoByID(param) {
  try {
    const response = await parse.Cloud.run(
      "v1_webapp_get_booking_info_by_id",
      param
    );
    // console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log(JSON.stringify(error));
    return error;
  }
}

export async function getDailyRideshipData(param) {
  try {
    const response = await parse.Cloud.run(
      "v1_webapp_get_daily_ridership_data",
      param
    );
    // console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log(JSON.stringify(error));
    return error;
  }
}

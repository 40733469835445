import React from "react";
import { connect } from "react-redux";
import { BackTop, Layout } from "antd";
import classNames from "classnames";

import Settings from "components/LayoutComponents/Settings";
import TopBar from "components/LayoutComponents/TopBar";
import SideMenu from "components/LayoutComponents/Menu";
import Breadcrumbs from "components/LayoutComponents/Breadcrumbs";
import Footer from "components/LayoutComponents/Footer";

import Loader from "../../components/LayoutComponents/Loader";
import styles from "./style.module.scss";

@connect(
  ({ settings, common }) => ({
    isMenuCollapsed: settings.isMenuCollapsed,
    common
  }),
  null
)
class MainLayout extends React.PureComponent {
  render() {
    const { children, isMenuCollapsed, common } = this.props;

    return (
      <Layout
        id="wrapper"
        className={classNames("wrapper", {
          enlarged: isMenuCollapsed
        })}
      >
        <Loader spinning={common.loading} />
        {/* //   className={classNames({
      //     settings__borderLess: isBorderless,
      //     settings__squaredBorders: isSquaredBorders,
      //     settings__fixedWidth: isFixedWidth,
      //     settings__menuShadow: isMenuShadow,
      //     settings__menuTop: isMenuTop,
      //   })}
      // > */}
        <BackTop />
        <TopBar />
        <Settings />
        <Layout hasSider>
          <SideMenu />
          <Layout className="content-page">
            <Layout.Content className={`content ${styles["layout-content"]}`}>
              <div className="container-fluid">
                <Breadcrumbs />
                <div className={styles.utils__content}>{children}</div>
              </div>
            </Layout.Content>
            <Layout.Footer>
              <Footer />
            </Layout.Footer>
          </Layout>
        </Layout>
      </Layout>
    );
  }
}

export default MainLayout;

/* eslint-disable camelcase */
import { all, takeEvery, put, call } from "redux-saga/effects";
import { notification } from "antd";
import { reactLocalStorage } from "reactjs-localstorage";
import {
  login,
  currentAccount,
  logout,
  checkEmail,
  sendRequestResetPassword
} from "../../services/auth";
import { types } from "./actions";
import { types as commonTypes } from "../common/actions";

export function* LOGIN({ payload }) {
  // console.log(payload);
  const { email, password } = payload;
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: true
    }
  });
  const success = yield call(login, email, password);
  if (success) {
    if (payload.rememberme !== undefined)
      reactLocalStorage.setObject("savedUserInfo", {
        email: payload.email,
        password: ""
      });

    notification.success({
      message: "Logged In",
      description: "You have successfully logged in"
    });
    yield put({
      type: types.LOAD_CURRENT_ACCOUNT
    });
  }
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: false
    }
  });
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: types.SET_STATE,
    payload: {
      loading: true
    }
  });

  const response = yield call(currentAccount);
  // console.log(JSON.stringify(response));
  if (response) {
    const firstname = response.get("firstname");
    const lastname = response.get("lastname");
    const avatar = response.get("user_photo");
    const employerProgram = response.get("employer_programs");
    const is_admin = response.get("is_admin");

    yield put({
      type: types.SET_STATE,
      payload: {
        userId: response.get("username"),
        name: `${firstname} ${lastname}`,
        email: response.get("email"),
        avatar: avatar !== undefined ? avatar.url() : undefined,
        role: "admin",
        authorized: true,
        is_admin,
        employer_programs: employerProgram !== undefined ? employerProgram : []
      }
    });
  }
  yield put({
    type: types.SET_STATE,
    payload: {
      loading: false
    }
  });
}

export function* CHECK_EMAIL({ payload }) {
  const response = yield call(checkEmail, payload);
  if (response.status === "success") {
    yield call(sendRequestResetPassword, payload);
    yield put({
      type: types.SET_STATE,
      payload: {
        bSuccessSectionVisible: true,
        bErrorSectionVisible: false
      }
    });
  } else {
    yield put({
      type: types.SET_STATE,
      payload: {
        bSuccessSectionVisible: false,
        bErrorSectionVisible: true
      }
    });
  }
}

export function* LOGOUT() {
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      isError: false,
      errContent: "",
      loading: false
    }
  });

  yield put({
    type: types.SET_STATE,
    payload: {
      userId: "",
      name: "",
      role: "",
      email: "",
      avatar: "",
      authorized: false,
      loading: false,
      employer_programs: []
    }
  });

  yield call(logout);
}

export default function* rootSaga() {
  yield all([
    takeEvery(types.LOGIN, LOGIN),
    takeEvery(types.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(types.LOGOUT, LOGOUT),
    takeEvery(types.CHECK_EMAIL, CHECK_EMAIL),
    LOAD_CURRENT_ACCOUNT() // run once on app load to check user auth
  ]);
}

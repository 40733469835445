/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import aws from "aws-sdk";
import parse from "./_parse";
import // devRegion,
// devAccessKeyId,
// devSecretAccessKey,
// devBucket,
// prodRegion,
// prodAccessKeyId,
// prodSecretAccessKey,
// prodBucket
"../config";

require("dotenv").config();

const env = window._env_.DEPLOYMENT_PROFILE;

aws.config.update({
  // region: env === "dev" ? devRegion : prodRegion,
  // accessKeyId: env === "dev" ? devAccessKeyId : prodAccessKeyId,
  // secretAccessKey: env === "dev" ? devSecretAccessKey : prodSecretAccessKey
  region:
    env === "dev" || env === "preDev"
      ? process.env.REACT_APP_DevRegion
      : process.env.REACT_APP_ProdRegion,
  accessKeyId:
    env === "dev" || env === "preDev"
      ? process.env.REACT_APP_DevAccessKeyId
      : process.env.REACT_APP_ProdAccessKeyId,
  secretAccessKey:
    env === "dev" || env === "preDev"
      ? process.env.REACT_APP_DevSecretAccessKey
      : process.env.REACT_APP_ProdSecretAccessKey
});
const s3 = new aws.S3();

export async function fetchDriversList() {
  try {
    const lists = await parse.Cloud.run("v1_webapp_get_fleet_driver_list");
    // console.log(lists);
    return lists;
  } catch (error) {
    console.log("fetchDriversList() = ", JSON.stringify(error));
    return error;
  }
}

export async function fetchFleetScheduleDataWithAutoCreate(param) {
  try {
    const lists = await parse.Cloud.run(
      "v1_webapp_get_fleet_dispatch_schedule_data",
      param
    );
    return lists;
  } catch (error) {
    console.log("fetchFleetScheduleData() = ", error);
    return [];
  }
}

export async function fetchFleetScheduleData(param) {
  try {
    const lists = await parse.Cloud.run(
      "v1_webapp_get_fleet_schedule_data",
      param
    );
    return lists;
  } catch (error) {
    console.log("fetchFleetScheduleData() = ", error);
    return [];
  }
}

export async function saveFleetScheduleData(param) {
  try {
    const lists = await parse.Cloud.run(
      "v1_webapp_save_fleet_schedule_data",
      param
    );
    return lists;
  } catch (error) {
    console.log("saveFleetScheduleData() = ", error);
    return [];
  }
}

export async function clonePreviousFleetScheduleData(param) {
  try {
    const lists = await parse.Cloud.run(
      "v1_webapp_clone_previous_fleet_schedule_data",
      param
    );
    return lists;
  } catch (error) {
    console.log("clonePreviousFleetScheduleData() = ", error);
    return [];
  }
}

export async function getFleetLatestScheduleDate() {
  try {
    const lists = await parse.Cloud.run(
      "v1_webapp_get_fleet_latest_schedule_date"
    );
    return lists;
  } catch (error) {
    console.log("clonePreviousFleetScheduleData() = ", error);
    return [];
  }
}

export async function verifyUserPhone(param) {
  try {
    // console.log("verifyUserPhone = ", param);
    const data = await parse.Cloud.run("v1_webapp_verify_user_phone", param);
    // console.log("verifyUserPhone = ", JSON.stringify(data));
    return data;
  } catch (error) {
    console.log("verifyUserPhone() = ", JSON.stringify(error));
    return error;
  }
}

export async function getFleetAvailableDrivers() {
  try {
    // console.log("getFleetAvailableDrivers = ", param);
    const data = await parse.Cloud.run("v1_webapp_get_fleet_available_drivers");
    // console.log("getFleetAvailableDrivers = ", JSON.stringify(data));
    return data;
  } catch (error) {
    console.log(
      "services/drivers.js::getFleetAvailableDrivers() = ",
      JSON.stringify(error)
    );
    return error;
  }
}

export async function setSelectDriverShuttle(param) {
  try {
    // console.log("setSelectDriverShuttle = ", param);
    const data = await parse.Cloud.run(
      "v1_webapp_select_driver_shuttle",
      param
    );
    // console.log("setSelectDriverShuttle = ", JSON.stringify(data));
    return data;
  } catch (error) {
    console.log(
      "services/drivers.js::setSelectDriverShuttle() = ",
      JSON.stringify(error)
    );
    return error;
  }
}

export async function releasetDriverShuttle(param) {
  try {
    // console.log("releasetDriverShuttle = ", param);
    const data = await parse.Cloud.run(
      "v1_webapp_release_driver_shuttle",
      param
    );
    // console.log("releasetDriverShuttle = ", JSON.stringify(data));
    return data;
  } catch (error) {
    console.log(
      "services/drivers.js::releasetDriverShuttle() = ",
      JSON.stringify(error)
    );
    return error;
  }
}

export async function sendPhoneConfirmation(param) {
  try {
    const data = await parse.Cloud.run(
      "v1_webapp_send_phone_confirmation",
      param
    );
    // console.log("sendPhoneConfirmation = ", data);
    return data;
  } catch (error) {
    console.log("sendPhoneConfirmation() = ", JSON.stringify(error));
    return error;
  }
}

export async function fetchDriverInfoByID(param) {
  try {
    const response = await parse.Cloud.run(
      "v1_webapp_get_driver_info_by_id",
      param
    );
    // console.log("fetchDriverInfoByID = ", JSON.stringify(response));
    return response;
  } catch (error) {
    console.log("fetchDriverInfoByID() = ", JSON.stringify(error));
    return error;
  }
}

export async function fetchLabel() {
  try {
    const data = await parse.Cloud.run("v1_webapp_get_user_fleet_name");
    return { status: 1, data };
  } catch (error) {
    console.log("fetchLabel() = ", JSON.stringify(error));
    return { status: 0, data: error };
  }
}

export async function uploadImageToS3(params) {
  const s3Params = {
    // Bucket: env === "dev" ? devBucket : prodBucket,
    Bucket:
      env === "dev" || env === "preDev"
        ? process.env.REACT_APP_DevBucket
        : process.env.REACT_APP_ProdBucket,
    Body: params,
    Key: params.name,
    Expires: 500,
    ContentType: params.type,
    ACL: "public-read"
  };

  // console.log(s3Params);

  return new Promise(resolve => {
    // eslint-disable-next-line func-names
    s3.upload(s3Params, function(err, data) {
      if (err) {
        // console.log("file upload fail");
        // console.log(err, err.stack); // an error occurred
        // console.log(JSON.stringify(err));
        resolve({ status: false, desc: err });
      } else {
        // console.log("file upload success", JSON.stringify(data));
        resolve({ status: true, desc: data });
      }
    });
  });
}

export async function createNewDriver(params) {
  // console.log("createNewShuttle = ", params);
  const {
    driver_user_id,
    status,
    dl_number,
    dl_expiration,
    driver_photo
  } = params;

  let statusCode = 0;
  if (status.trim() === "Active") statusCode = 0;
  else if (status.trim() === "Suspended") statusCode = 1;
  else if (status.trim() === "Dismissed") statusCode = 2;

  const realParam = {
    driver_user_id,
    status: parseInt(statusCode, 10),
    dl_number: dl_number.trim() === "" ? null : dl_number,
    dl_expiration: dl_expiration.trim() === "" ? null : dl_expiration,
    driver_photo
  };
  // console.log("new driver data = ", realParam);
  try {
    const response = await parse.Cloud.run(
      "v1_webapp_create_new_driver",
      realParam
    );
    // console.log("new driver = ", response);
    return response;
  } catch (error) {
    console.log(JSON.stringify(error));
    return error;
  }
}

export async function createNewUser(params) {
  // console.log(params);
  try {
    const response = await parse.Cloud.run("v1_webapp_create_new_user", params);
    // console.log(response);
    // console.log(
    //   "createNewUser function in drivers.js = ",
    //   JSON.stringify(response)
    // );
    return response;
  } catch (error) {
    console.log(JSON.stringify(error));
    return error;
  }
}

export async function updatePassword(params) {
  try {
    const response = await parse.Cloud.run(
      "v1_webapp_reset_driver_password",
      params
    );
    return response;
  } catch (error) {
    console.log(JSON.stringify(error));
    return error;
  }
}

export async function updateExistDriver(params) {
  // console.log("udpateExistDriver = ", params);
  const {
    driver_id,
    status,
    dl_number_replaced,
    dl_number,
    dl_expiration,
    cdl,
    labor_classification,
    labor_class_code,
    employee_id,
    rider_assistance_accommodation,
    service_assignment_date,
    other_licenses,
    background_checks,
    driving_record,
    driver_photo_replaced,
    driver_photo,
    dl_photo_replaced,
    dl_photo
  } = params;

  // const formatted_dl_expiration = `${dl_expiration.substring(
  //   5,
  //   7
  // )}/${dl_expiration.substring(8, 10)}/${dl_expiration.substring(0, 4)}`;
  // const formatted_employment_date = `${employment_date.substring(
  //   5,
  //   7
  // )}/${employment_date.substring(8, 10)}/${employment_date.substring(0, 4)}`;
  // const formatted_service_assignment_date = `${service_assignment_date.substring(
  //   5,
  //   7
  // )}/${service_assignment_date.substring(
  //   8,
  //   10
  // )}/${service_assignment_date.substring(0, 4)}`;

  let statusCode = 0;
  if (status.trim() === "Active") statusCode = 0;
  else if (status.trim() === "Suspended") statusCode = 1;
  else if (status.trim() === "Dismissed") statusCode = 2;

  let realParam = {
    driver_id,
    labor_class_code: parseInt(labor_class_code, 10) + 1,
    status: parseInt(statusCode, 10),
    dl_number_replaced,
    dl_expiration: dl_expiration === "12/31/1969" ? null : dl_expiration,
    cdl: cdl === "Yes",
    labor_classification:
      labor_classification !== null && labor_classification.trim() === ""
        ? null
        : labor_classification,
    employee_id:
      employee_id !== null && employee_id.trim() === "" ? null : employee_id,
    rider_assistance_accommodation:
      rider_assistance_accommodation !== null &&
      rider_assistance_accommodation.trim() === ""
        ? null
        : rider_assistance_accommodation,
    service_assignment_date:
      service_assignment_date === "12/31/1969" ? null : service_assignment_date,
    other_licenses:
      other_licenses !== null && other_licenses.trim() === ""
        ? null
        : other_licenses,
    background_checks:
      background_checks !== null && background_checks.trim() === ""
        ? null
        : background_checks,
    driving_record:
      driving_record !== null && driving_record.trim() === ""
        ? null
        : driving_record,
    driver_photo_replaced,
    dl_photo_replaced
  };

  if (dl_number_replaced)
    realParam = {
      ...realParam,
      dl_number:
        dl_number !== null && dl_number.trim() === "" ? null : dl_number
    };

  if (driver_photo_replaced) realParam = { ...realParam, driver_photo };

  if (dl_photo_replaced) realParam = { ...realParam, dl_photo };

  // console.log(realParam);

  try {
    const data = await parse.Cloud.run("v1_webapp_edit_driver", realParam);
    return data;
  } catch (error) {
    console.log(JSON.stringify(error));
    return error;
  }
}
